html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brd {
  border: 1px solid #b4c5d5;
}

.colorSep {
  color: #b4c5d5;
  background-color: #b4c5d5;
}


.bgColorAlt {
  background-color: #f2f2f2;
}

.filterBox {
  border: 1px solid #f2f2f2;
  padding: 3px 5px;
  display: flex;
}

.filterBoxNoFlx {
  border: 1px solid #f2f2f2;
  padding: 3px 5px;
}

.filterLbl {
  font-size: x-small;
  margin-bottom: 1px;
  color: #336699;
  line-height: 23px;
}

.fltInfLbl {
  font-size: 12px;
  font-weight: 600;
  color: #336699;
  margin-right: 3px;
}

.fltInfLbl:after {
  content: ':';
}

.fltInfVal {
  font-size: 12px;
  color: black;
}


.smallerLbl {
  font-size: smaller;
  font-weight: 500;
}

.smallLbl {
  font-size: small;
  font-weight: 500;
}

#root {
  height: 100%;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-form-item-explain {
  font-size: 12px !important;
  margin-bottom: 8px;
}


/* -- START POPUP -- */
.pup-apis-content {
  max-width: 1100px;
  background: rgb(255, 255, 255);
  margin: 15px auto !important;
  width: calc(100% - 40px);
  padding: 21px;
  background-clip: padding-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pup-apis-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].pup-apis-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.pup-apis-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].pup-apis-overlay {
  background: transparent;
}

.pup-apis-content .pup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.pup-apis-content .pup-header .pup-title {
  text-align: center;
  margin: 0;
  font-weight: 500;
  font-size: 17px;
  color: #53539b;
}

.pup-apis-content .pup-header .pup-hd-left {
  width: 35px;
}

.pup-apis-content .pup-header .pup-close {
  display: inline-block;
  cursor: pointer;
  z-index: 99;
  border-radius: 10px;
  line-height: 0;
  border: 1px solid #ccc;
  padding: 7px;
}

.pup-apis-content .pup-header .pup-close:hover {
  border-style: dashed;
}

.pup-apis-content .pup-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.pup-apis-content .pup-footer {
  margin-top: 20px;
  padding-top: 7px;
  border-top: 1px solid #ccc;
}

/* -- END POPUP -- */

.ctx .left {
  display: flex;
  justify-content: flex-end;
  color: #53539b;
  font-weight: 600;
  font-size: 12px;
}

.ctx .right {
  color: #53539b;
  font-size: 12px;
}

.ctx {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 5px;
}

.table thead th {
  font-weight: 600;
  font-size: 13px;
}

.sectionHd {
  font-weight: 700;
  font-size: 15px;
  text-decoration: underline;
  color: #53539b;
}

.sectionHd2 {
  font-weight: 600;
  font-size: 14px;
  color: #53539b;
}

.sectionHd3 {
  font-weight: 500;
  font-size: 13px;
  color: #53539b;
}

.txt2 {
  font-size: 14px;
}

.txt3 {
  font-size: 13px;
}

.warn {
  color: red;
  white-space: pre;
}

.feedbackBox {
  max-height: 300px;
  max-width: 500px;
  overflow-x: auto;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
}

.btn-large {
  height: auto !important;
  font-size: 14px !important;
  padding: 5px !important;
}

.linebreak {
  white-space: pre-line;
}

.cmdIdx{
  font-size: 12px;
}

.pup-apis-content{
  max-width: 1300px;
}